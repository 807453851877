<template>
  <div

    v-if="!!item"
    class="
      offer-card 
      tw-mx-auto tw-mb-4
    "
  >
    <template v-if="!!image">
      <div

        class="card-background w-100 h-100"
        :style="{ backgroundImage: `url(${image})` }"
      >
       <div class="tw-ml-3.5 tw-font-sfprodisplay-semibold counter tw-w-24 tw-rounded-3xl tw-h-4">
           <div class="tw-mx-2 tw-flex tw-justify-center tw-leading-4">
            {{ tripsCount }} Balloon Trip{{ tripsCount > 1 ? 's' : '' }}
           </div>
        </div>
        <div class="tw-flex tw-justify-center">
        <div v-bind:style="[ expandDescription ? {'bottom': '6rem'} : {'bottom' : '3rem'} ]" class="info-block ">
          <div class="tw-font-poppins-bold tw-flex tw-text-sm" >{{ item.name }} </div>
        </div>
         <div v-bind:style="[ expandDescription ? {'bottom': '5.25rem'} : {'bottom' : '2.25rem'} ]" class="info-block">
          <div class="tw-font-poppins-bold countryfont">{{ item.country }} </div>
        </div>
        <!-- <div class="info-block tw-bottom-6">
            <div class="w-font-poppins-bold fontset tw-mx-7">
                  <template v-if="!expandDescription">
                    {{ item.description.slice(0,150)}}...
                    <span
                      class="see-more"
                      @click="
                        () => {
                          expandDescription = !expandDescription;
                        }
                      "
                      >Read More</span
                    >
                  </template>
                  <template v-else>
                    {{ item.description }}
                  </template>
                </div>
        </div> -->
        </div>
      </div>

    </template>
    <template v-else>
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </template>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { transformImage } from '@/utils/transformImage';

export default {
  name: 'MobileDestinationCard',
  props: {
    item: Object
  },
  data: () => ({
    image: '',
    expandDescription: false,
    
  }),
  computed: {
    tripsCount() {
      return this.item.experiences ? this.item.experiences.length : 0;
    }
  },
  watch: {
    item: function() {
      this.setImage();
    }
  },
  mounted() {
    this.setImage();
  },
  methods: {
    ...mapMutations([ 'setExperience' ]),
    goToCityPage() {
      this.setExperience(this.item);
      //this.$router.push({ path: '/destination/' + this.item.name });
      this.$router.push({
        name: 'DestinationPage',
        params: {
          cityName: this.item.name
        },
        query: { mode: 'Discover' },
      });
    },
    setImage() {
      var highResImage = new Image();
      highResImage.onload = () => {
        this.image = transformImage(this.item.background_image, 504);
      };
      highResImage.src = transformImage(this.item.background_image, 504);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';
.offer-card {
  width: 47vw;
  height: 67vw;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  &:hover {
    .card-background {
      box-shadow: inset 0 0 100rem rgba(0, 0, 0, 1);
    }
    .info-block {
      opacity: 1;
    }
  }


  .card-background:hover .discover {
    visibility: visible;
  }

  .card-background {
    background-position: center center;
    background-size: cover;
    transition: opacity 0.3s ease-in-out;
  }

  .card-background .discover {
    visibility: hidden;
    text-align: center;
    left: 15%;
    top: 45%;
  }

  .info-block {
    //bottom: 0;
    //position: absolute;
    color: white;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
  //left: 25%;
  //bottom: 20px;
 //transform: translate(-25%, -25%);
  margin: 0 auto;
    // background: linear-gradient(
    //   360deg,
    //   #00000099 25%,
    //   rgba(0, 0, 0, 40%) 60%,
    //   rgba(0, 0, 0, 30%) 70%,
    //   rgba(0, 0, 0, 20%) 80%,
    //   rgba(0, 0, 0, 10%) 90%,
    //   rgba(0, 0, 0, 5%) 95%,
    //   rgba(0, 0, 0, 2%) 98%,
    //   rgba(0, 0, 0, 1%) 99%,
    //   rgba(0, 0, 0, 0%) 100%
    // );
    //padding-top: 40px;

    h3 {
      font-size: 1.7vw;
      //line-height: 32px;
      margin-bottom: 0;
      line-height: 10px;
    }
  }
}
   .counter {
      font-size: 0.5rem;
      background-color: rgba(196, 196, 196, 0.6);
      position: absolute;
      top: 5%;
      color: #ffffff;
  }
  .fontset {
      font-size: 0.2rem;
      line-height: 0.2rem;
  }
  .countryfont {
      font-size: 0.5rem;
      line-height: 0.95rem;
  }
  .offer-card {
  .v-skeleton-loader {
    height: 100%;
    .v-skeleton-loader__card {
      height: 100% !important;
      .v-skeleton-loader__image {
        height: 80%;
      }
    }
  }
}
</style>


