<template>
  <div class="body">
    <DestinationSlider />
    <TopCategories />
    <AllCategories />
  </div>
</template>

<script>
import DestinationSlider from './DestinationSlider.vue';
import TopCategories from './TripCategories.vue';
import AllCategories from './AllCategories.vue';
export default {
  name: 'MobileCategoryView',
  components: {
    DestinationSlider,
    TopCategories,
    AllCategories,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/app.scss';
.body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
