<template>
    <div class="header body">
        All Categories
    <VueSlickCarousel v-bind="settings" class="tw-mt-4">
        <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/work.png`)"
                />
          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">WORK</div>
      </div>
       <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/family.png`)"
                />

          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">FAMILY</div>
      </div>
       <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/party.png`)"
                />

          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">PARTY</div>
      </div>
       <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/work.png`)"
                />

          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">ADVENTURE</div>
      </div>
       <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/work.png`)"
                />

          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">ROMANTIC</div>
      </div>
       <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/work.png`)"
                />

          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">RELAXING</div>
      </div>
       <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/work.png`)"
                />

          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">HISTORY</div>
      </div>
      <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/work.png`)"
                />

          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">BEACH</div>
      </div>
       <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/work.png`)"
                />
          <div class="tw-top-0 tw-ml-2 tw-text-white tw-text-sm tw-font-sfprodisplay-semibold tw-absolute">SPORTS</div>
      </div>
    </VueSlickCarousel>
     </div>
</template>

<script> 
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
export default {
  name: 'AllCategories',
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        'infinite': false,
        'slidesToShow': 1,
        'speed': 500,
        'rows': 2,
        'slidesPerRow': 2,
        'centerMode': true,
        'centerPadding': '20px',
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/app.scss';
.header {
    font-family: 'SFProDisplaySemibold';
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: 0.03rem;
    margin-left: 2rem;
    margin-bottom: 0.75rem;
    height: 60vh;
}

.body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
