<template>
  <div class="tw-w-full tw-my-4 tw-pr-6">
    <div class="tw-border tw-py-2 tw-flex tw-flex-row tw-rounded-xl">
      <SearchIcon class="tw-w-6 tw-h-6 tw-ml-4 tw-mr-3 tw-mt-1.5"/>
      <input
        class="label"
        type="text"
        placeholder="Search for trips by destinations"
        v-model="searchedText"
      />
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/assets/mobile/categoryview/search.svg';
export default {
  components: {
    SearchIcon,
  },
  props: {
    value: String
  },
  computed: {
    searchedText: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  font-family: 'SFProTextRegular' !important;
  font-size: 4vw !important;
  width: 65vw;
}
</style>
