<template>
  <div>
    <div class="header">Trip Categories</div>
    <div class="tw-flex tw-flex-col">
      <div class="categories-container tw-relative">
           <img
                  :src="
                    require(`@/assets/mobile/categoryview/relaxing.png`)"
                />
        <div class="tw-flex tw-justify-center">
          <div class="tw-top-1/2 tw-absolute">RELAXING</div>
        </div>
      </div>
      <div class="categories-container tw-relative">
          <img
                  :src="
                    require(`@/assets/mobile/categoryview/adventure.png`)"
                />
        <div class="tw-flex tw-justify-center">
          <div class="tw-top-1/2 tw-absolute">ADVENTURE</div>
        </div>
      </div>
      <div class="categories-container tw-relative">
         <img
                  :src="
                    require(`@/assets/mobile/categoryview/adventure.png`)"
                />
        <div class="tw-flex tw-justify-center">
          <div class="tw-top-1/2 tw-absolute">ROMANTIC</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DestinationSlider'
};
</script>

<style lang="scss" scoped>
.header {
  font-family: "SFProDisplaySemibold";
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: 0.03rem;
  margin-left: 2rem;
  margin-bottom: 1.75rem;
}

.categories-container {
  width: 89%;
  height: 89vw;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;
  border-radius: 0.625rem;
  font-size: 1.25rem;
  color: #ffffff;
  font-family: 'SFProDisplaySemibold';
}

.body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
