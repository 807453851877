<template>
  <div class="header">
    Collections
    <search-destinations-field v-model="searchedDestination" />
    <destination-items-container :items="destinationItems" :itemComponentName="offersTabsNames.destinations.cardName"/>
  </div>
</template>

<script>
import SearchDestinationsField from '@/views/landingPage/landingPageComponents/SearchDestinationsField.vue';
import destinationItemsContainer from './destinationItemsContainer.vue';
import { mapActions, mapGetters } from 'vuex';
import { offersTabsNames } from '@/views/landingPage/constants';
export default {
  name: 'DestinationSlider',
  data() {
    return {
      searchedDestination: '',
      offersTabsNames,
    };
  },
  components: {
    SearchDestinationsField,
    destinationItemsContainer,
  },
  computed: {
    ...mapGetters([
      'topExperiences',
      'topExperiencesLoaded',
    ]),
    destinationItems() {
      let items = [];
      items = this.searchedDestination.length
        ? this.topExperiences.filter(experience =>
          experience.name
            .toLowerCase()
            .includes(this.searchedDestination.toLowerCase())
        )
        : this.topExperiences;
      return items;
    }
  },
  methods: {
    ...mapActions([ 'loadTopExperiences' ]),
  },
  created: async function() {
    !this.topExperiencesLoaded && await this.loadTopExperiences();
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-family: "SFProDisplayBold";
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.03rem;
  margin-left: 2rem;
  margin-bottom: 1.75rem;
}

.body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
