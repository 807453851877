<template>
  <div class="offers-list">
    <div v-if="!!items.length" >
      <carousel :paginationEnabled="false">
        <slide v-for="(item,index) in items" :key="index">
          <component
              :is="itemComponentName"
              :item="item"
            />
        </slide>
      </carousel>
    </div>
  </div>
</template>      
<script>
import DestinationCard from './MobileDestinationCard.vue';
import { Carousel, Slide } from 'vue-carousel';
export default {
  name: 'DestinationItemsContainer',
  components: {
    DestinationCard,
    Carousel,
    Slide
  },
  props: {
    items: Array,
    itemComponentName: String
  },
  computed: {
    searchedText: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.offer-card-col {
 // padding: 3rem 2.5rem 0rem 0rem !important;
}
</style>
